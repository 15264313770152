@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
body {
  margin: 0;
  font-family: Anton;
  color: #dcdcdc !important;
  background: radial-gradient(
      57.43% 81.13% at 50% 100%,
      rgba(14, 94, 116, 0.21) 0%,
      rgba(14, 94, 116, 0) 100%
    ),
    #13151e !important;
  position: relative;
  min-height: 100vh;
  padding-bottom: 200px;

  /* background: #121b25 !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* /////////////////animation loading////////////////// */
.fadeinout {
  animation: fadeinout 1s infinite;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background: #2a2a2a;
}
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 20px;
}
/* animation loading text */
.loadingTextNft{
  left: 50%;
  top: 50%;
  font-size: 36px;
  font-family: Anton;
  font-weight: bold;
  letter-spacing: 4.4px;
  text-transform: capitalize;
  position: absolute;
  overflow: hidden;
  transform: translate(-50%, -50%);
  }
  .loadingTextNft:before {
  color:  #444;
  content: attr(data-loading-text);
  }
  .loadingTextNft:after {
  top: 0;
  left: 0;
  width: 0;
  opacity: 1;
  color: #DCDCDC;
  overflow: hidden;
  position: absolute;
  content: attr(data-loading-text);
  animation: loadingText 5s infinite;
  }
  @keyframes loadingText {
  0% {
  width: 0;
  }
  100% {
  width: 100%;
  }
  }

  /* .MuiBox-root::-webkit-scrollbar {
    width: 10px !important;
    border-radius: 10px !important;
  } */
  /* .MuiBox-root::-webkit-scrollbar {

    color: blue !important
  }

  .MuiBox-root::-webkit-scrollbar-track {
    background: #13141E;
  }

  .MuiBox-root::-webkit-scrollbar-thumb {
    background: #525960; 
    border-radius: 26px;
  } */
  
  .marquee-container::-webkit-scrollbar {
    height: 8px;
  }
  .marquee-container::-webkit-scrollbar-track {
    background: #13141E;
  }

  .marquee-container::-webkit-scrollbar-thumb {
    background: #525960; 
    border-radius: 26px;
   
  }



/* Wallet Model Style Start */
/* .gfuDAO {
  background-color: transparent !important;
  display: flex !important;
  flex-direction: column !important;
}
.iypxSt {
  flex-direction: row !important;
  align-items: center !important;
  border-radius: 10px !important;
  padding: 10px 16px !important;
  border-left: 20px solid rgb(199, 199, 199);
  border-right: 20px solid rgb(199, 199, 199);
}
.fqonLZ {
  width: 100px !important;
  height: 85px !important;
  background-color: rgb(199, 199, 199) !important;
  padding: 0px 10px;
}
.kFITWz {
  width: 0px !important;
  display: none;
} */

.scroolbox::-webkit-scrollbar {
  width: 5px;
}

/* Track  */
.scroolbox::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

/* / Handle / */

.scroolbox::-webkit-scrollbar-thumb {
  background: "#000";
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}
/* Wallet Model Style End */

.walletconnect-modal__footer {
  overflow-x: scroll;
  justify-content: flex-start !important;
  padding-bottom: 6px;
}

.walletconnect-modal__footer::-webkit-scrollbar {
  height: 1px;
}

.walletconnect-modal__footer::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 8px;
}

.walletconnect-modal__footer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.walletconnect-modal__footer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.linkStyle:hover {
  color: #fff !important;
  text-decoration: none !important;
}
